import React, { useState, useEffect } from "react"
import Container from "../../components/container/container"
import DividerFilter from "../../components/divider/divider-filter"
import style from "./work.mod.scss"
import ArrowRightBlack from "../../components/icons/arrow-right-black"
import Slide from "../../components/work/slide"

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css"
import "swiper/css/grid"
import "swiper/css/pagination"
import { Grid, Pagination, Navigation } from "swiper"

const Work = ({ caseStudies, categories }) => {
  const shuffleArray = (array) => {
    return array.map((value) => ({ value, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ value }, i) => ({ ...value, position: i }))
  }
  const [activeFilter, setActiveFilter] = useState("View All")
  const [filteredItems, setFilteredItems] = useState(caseStudies)

  const handleFilter = (filter) => {
    setActiveFilter(filter)
  }

  useEffect(() => {
    const shuffledCaseStudies = shuffleArray(caseStudies)
    if (activeFilter === "View All") {
      setFilteredItems(shuffledCaseStudies)
    } else {
      let arr = shuffledCaseStudies.filter(
        (item) => item.category.title === activeFilter
      )
      setFilteredItems(arr)
    }
  }, [activeFilter])

  const availableCategories = Object.values(
    caseStudies.reduce(
      (acc, obj) => ({ ...acc, [obj.category.title]: obj.category }),
      {}
    )
  ).map((item) => item.title)

  const filteredCategories = categories.filter((item) =>
    availableCategories.includes(item.title)
  )

  return (
    <div className={style.work} id={"our-work"}>
      <Container width={"large"} gutters>
        <DividerFilter
          heading={"Our work"}
          items={filteredCategories}
          handleFilter={handleFilter}
          activeFilter={activeFilter}
          availableCategories={availableCategories}
        />

        <Swiper
          slidesPerGroup={1}
          slidesPerView={1}
          simulateTouch={false}
          grabCursor={false}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          modules={[Grid, Pagination, Navigation]}
          grid={{
            rows: 1,
          }}
          breakpoints={{
            640: {
              slidesPerGroup: 2,
              slidesPerView: 2,
              grid: {
                rows: 2,
              },
            },
            1000: {
              slidesPerView: 3,
              slidesPerGroup: 3,

              grid: {
                rows: 2,
              },
            },
          }}
        >
          {filteredItems.map((item, i) => (
            <SwiperSlide key={item.id}>
              <Slide {...item} />
            </SwiperSlide>
          ))}

          <div className={style.work__sliderNav}>
            <div className={style.work__buttons}>
              <CarouselPrev />
              <CarouselNext />
            </div>
          </div>
        </Swiper>
      </Container>
    </div>
  )
}

export default Work

const CarouselPrev = () => (
  <div className={style.work__buttons_prev}>
    <div aria-label="Previous" className="swiper-button-prev">
      <div className={style.work__buttons_arrow}>
        <ArrowRightBlack />
      </div>
    </div>
  </div>
)

const CarouselNext = () => (
  <div className={style.work__buttons_next}>
    <div aria-label="Next" className="swiper-button-next">
      <div className={style.work__buttons_arrow}>
        <ArrowRightBlack />
      </div>
    </div>
  </div>
)

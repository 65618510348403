import React from "react"
import style from "./divider-filter.mod.scss"
import { motion } from "framer-motion"

const DividerFilter = ({ heading, items, handleFilter, activeFilter }) => {
  const variants = (i) => {
    return {
      offscreen: {
        opacity: 0,
      },
      onscreen: {
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0,
          duration: 1,
          delay: i / 3.5,
        },
      },
    }
  }

  return (
    <div className={style.filter}>
      <h4>{heading}</h4>
      {/* <div className={style.filter__filters}>
        <div
          className={style.filter__filters_item}
          onClick={() => handleFilter("View All")}
        >
          <p className={activeFilter === "View All" && style.filter__active}>
            {"View All"}
          </p>
        </div>

        {items.map((item, i) => (
          <motion.div
            key={i}
            variants={variants(i)}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <div
              key={item.id}
              className={style.filter__filters_item}
              onClick={() => handleFilter(item.title)}
            >
              <p
                className={activeFilter === item.title ? style.filter__active : "false"}
              >
                {item.title}
              </p>
            </div>
          </motion.div>
        ))}
      </div> */}
    </div>
  )
}

export default DividerFilter

import React from "react"
import style from "./divider.mod.scss"

const Divider = ({ text, heading, items }) => {
  return (
    <div>
      {heading || text && (
      <div className={style.divider}>
        {!heading && text && <p>{text}</p>}
        {heading && <h4>{heading}</h4>}
      </div>
      )}
      {!heading && !text && (
      <div className={style.divider_small}>
        {!heading && text && <p>{text}</p>}
        {heading && <h4>{heading}</h4>}
      </div>
      )}      
    </div>

  )
}

export default Divider

import React from "react"

const Scroll = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 113">
      <g data-name="Group 12920">
        <text
          transform="rotate(-90 14.5 2.5)"
          fontSize="12"
          fontFamily="ARSMaquettePro"
        >
          <tspan x="0" y="0">
            Scroll
          </tspan>
        </text>
        <path data-name="Line 528" d="M9.5 113h-1V38h1z" />
      </g>
    </svg>
  )
}

export default Scroll

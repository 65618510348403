import { GatsbyImage } from "gatsby-plugin-image"
import React, { useEffect, useRef } from "react"
import Container from "../../components/container/container"
import style from "./hero.mod.scss"
import { motion } from "framer-motion"
// import ArrowRightBlack from "../../components/icons/arrow-right-black"
import Scroll from "../../components/icons/scroll"

const Hero = ({
  content: { heading, backgroundImage, backgroundVideo, backgroundVideoMobile },
  handleMoveToStory,
  name,
  isActive,
  windowWidth
}) => {
  const headingVariants = {
    offscreen: {
      x: 30,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 1.4,
        delay: 1.2,
      },
    },
  }

  const scrollButtonVariants = {
    offscreen: {
      opacity: 0,
    },
    onscreen: {
      opacity: 1, 
      transition: {
        type: "spring",
        bounce: 0.6,
        duration: 0.8,
        delay: 1.5,
      },
    },
  }
  
  const videoRef = useRef(null);
  
  useEffect(() => {
    if (videoRef && videoRef.current) {
      if (isActive) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  }, [isActive]);

  return (
    <div className={style.hero}>
      <div className={style.hero__scroll_button}>
        <motion.div
          variants={scrollButtonVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.2 }}
        >
          <div
            onClick={() => handleMoveToStory()}
            className={style.hero__scroll_button_bg}
          >
            <Scroll />
          </div>
        </motion.div>
      </div>
      <div className={style.hero_customflicker}></div>
      <div className={style.hero__image}>
        {!backgroundVideo && <GatsbyImage
          image={backgroundImage.gatsbyImageData}
          alt="Harvey Cameron hero image"
        />}
        {backgroundVideo && <>
          <video poster={backgroundImage.url} autoPlay playsInline muted loop ref={videoRef}>
            {windowWidth >= 992 && <source src={backgroundVideo.video.streamingUrl} type="video/mp4" />}
            {windowWidth >= 992 && <source src={backgroundVideo.url} type="video/mp4" />}
            {windowWidth < 992 && <source src={backgroundVideoMobile.video.streamingUrl} type="video/mp4" />}
            {windowWidth < 992 && <source src={backgroundVideoMobile.url} type="video/mp4" />}
          </video>
          <div className={style.hero__overlay} />
        </>}
      </div>
      <Container fullHeight gutters width={"large"}>
        <div className={style.hero__content}>
          <motion.div
            variants={headingVariants}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true, amount: 0.2 }}
          >
            <h1>
              {heading}
            </h1>
          </motion.div>
        </div>
      </Container>
    </div>
  )
}

export default Hero

import React from "react"

const Section = ({ children, id, grey = false, dark = false, darkGrey = false}) => {
  return (
    <div className={`section ${id && id} ${grey && "section--grey"} ${dark && "section--dark"} ${darkGrey && "section--dark-grey"}`}>
      {children}
    </div>
  )
}

export default Section

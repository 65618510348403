import { GatsbyImage } from "gatsby-plugin-image"
import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Container from "../../components/container/container"
import Divider from "../../components/divider/divider"
import style from "./solutions.mod.scss"

const Solutions = ({
  content: { heading, subHeading, backgroundImage, columns },
}) => {
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const variants = (i) => {
    return {
      offscreen: {
        y: 150,
        opacity: 0,
      },
      onscreen: {
        y: 0,
        opacity: 1,
        transition: {
          type: "spring",
          bounce: 0,
          duration: 2,
          delay: i / 2,
        },
      },
    }
  }

  const headingVariants = {
    offscreen: {
      x: 30,
      opacity: 0,
    },
    onscreen: {
      x: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.2,
        duration: 2.5,
      },
    },
  }

  return (
    <div className={style.solutions}>
      <div className={style.solutions__image}>
        <GatsbyImage alt=""  image={backgroundImage.gatsbyImageData} />
      </div>
      <Container width={"large"} gutters>
        <div className={style.solutions__divider}>
        <Divider text={subHeading} />
        </div>
        <motion.div
          variants={headingVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{  }}
        >
          <h2>{heading}</h2>
        </motion.div>
        <div className={style.solutions__columns}>
          {columns.map(({ title, description, items }, i) => (
            <div key={i} className={style.solutions__column}>
              <h6>{title}</h6>
              {/* <p className={style.solutions__column_desc}>{description}</p> */}
              <motion.div
                className={style.solutions__list}
                variants={variants(i)}
                initial="offscreen"
                whileInView="onscreen"
                viewport={{ once: true }}
              >
                {items.map(({ text }, index) => (
                  <p key={index}>{text}</p>
                ))}
              </motion.div>
            </div>
          ))}
        </div>
      </Container>
      <Container width={"large"} gutters>
        <div className={style.solutions__columns_mobile}>
          {columns.map(({ title, description, items }, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}-content`}
              onChange={handleChange(`panel${index}-content`)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-content`}
              >
                <Typography component={'div'}>
                  <div className={style.solutions__title}>{title}</div>
                  {/* <div>{description}</div> */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component={'div'}>
                  <div className={style.solutions__list}>
                    {items.map(({ text }, index) => (
                      <p key={index}>{text}</p>
                    ))}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Solutions
